.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0 4px;
    margin: auto;
    width: 60%;
}
  
.column {
    -ms-flex: 25%;
    flex: 25%;
    padding: 0 4px;
}
  
.column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
}